import { Card, Grid, Skeleton } from '@mui/material';
import { PlacidCanvasCard } from 'features/design-templates/PlacidCanvasCard';
import { PlacidTemplate } from 'features/design-templates/types';
import { range } from 'lodash';
import { MouseEvent } from 'react';
import { TemplateSelectionsPlacidTemplate } from 'services/generated/template-service/types.gen';
import styled from 'styled-components';

type Props = {
  templates: PlacidTemplate[] | null;
  isFetchingTemplates: boolean;
  templateCount: number;
  lastClickedTemplateIndex: number | null;
  isGeneratingImage: boolean;
  isGeneratingEditToken: boolean;
  onEditClick: (event: MouseEvent<HTMLElement>, template: PlacidTemplate, index: number) => void;
  onDownloadClick: (
    event: MouseEvent<HTMLElement>,
    template: TemplateSelectionsPlacidTemplate,
    index: number
  ) => void;
};

export const TemplateGrid = ({
  templates,
  isFetchingTemplates,
  templateCount,
  lastClickedTemplateIndex,
  isGeneratingImage,
  isGeneratingEditToken,
  onEditClick,
  onDownloadClick
}: Props) => (
  <DesignTemplatesContainer container={true} spacing={4}>
    {isFetchingTemplates &&
      range(0, templateCount).map(index => (
        <Grid key={index} item={true} xs={12} sm={6} md={4} alignItems="stretch">
          <Card elevation={0}>
            <Skeleton animation="wave" variant="rectangular" height={240} />
          </Card>
        </Grid>
      ))}

    {templates?.map((template, index) => {
      const isLoading =
        lastClickedTemplateIndex === index && (isGeneratingImage || isGeneratingEditToken);

      return (
        <Grid key={template.uuid} item={true} xs={12} sm={6} md={4} alignItems="stretch">
          <PlacidCanvasCard
            templateUuid={template.uuid}
            accessToken={template.accessToken}
            prefilledCanvasSchema={template.isSourceTemplate ? template.canvasSchema : undefined}
            isLoading={isLoading}
            onEditClick={event => onEditClick(event, template, index)}
            onDownloadClick={event => onDownloadClick(event, template, index)}
            templateIterationId={template.iterationId}
          />
        </Grid>
      );
    })}
  </DesignTemplatesContainer>
);

const DesignTemplatesContainer = styled(Grid)``;
