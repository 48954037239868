import { GenerateDesignTemplatesOptions } from 'features/design-templates/form/DesignTemplatesForm';
import { PlacidTemplate } from 'features/design-templates/types';
import { useGenerateTemplateTexts } from 'features/design-templates/useGenerateTemplateTexts';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export const useTemplateTextGeneration = (
  templates: PlacidTemplate[] | null,
  setTemplates: Dispatch<SetStateAction<PlacidTemplate[] | null>>,
  createDesignTemplatesOptions: GenerateDesignTemplatesOptions | null
) => {
  const { mutateAsync: generateTemplateTexts } = useGenerateTemplateTexts();
  const [hasGeneratedTexts, setHasGeneratedTexts] = useState(false);

  useEffect(() => {
    if (hasGeneratedTexts || !createDesignTemplatesOptions || !templates || !templates.length) {
      return;
    }

    setHasGeneratedTexts(true);

    (async () => {
      const result = await generateTemplateTexts({
        userPrompt: createDesignTemplatesOptions.prompt,
        count: templates.length
      });
      if (!result) {
        return;
      }

      // Apply texts to the canvasSchema of all templates
      setTemplates(
        prevTemplates =>
          prevTemplates?.map((template, idx) => {
            const res = result[idx];

            const updatedCanvasSchema = res
              ? {
                  ...template.canvasSchema,
                  headline: {
                    ...template.canvasSchema.headline,
                    text: res.headline.text
                  },
                  subtitle: {
                    ...template.canvasSchema.subtitle,
                    text: res.subtitle.text
                  },
                  'primary-color': {
                    ...template.canvasSchema['primary-color'],
                    color: res['primary-color'].color
                  }
                }
              : template.canvasSchema;

            return { ...template, canvasSchema: updatedCanvasSchema };
          }) ?? null
      );
    })();
  }, [
    createDesignTemplatesOptions,
    generateTemplateTexts,
    hasGeneratedTexts,
    templates,
    setTemplates
  ]);

  const resetTextGeneration = () => {
    setHasGeneratedTexts(false);
  };

  return {
    hasGeneratedTexts,
    resetTextGeneration
  };
};
