import Toast from 'components/toasts/Toast';
import { GenerateDesignTemplatesOptions } from 'features/design-templates/form/DesignTemplatesForm';
import { useShowPlacidEditorModal } from 'features/design-templates/PlacidEditorModal';
import { PlacidTemplate } from 'features/design-templates/types';
import { useGenerateTemplateImage } from 'features/design-templates/useGenerateTemplateImage';
import { useGetTemplateEditToken } from 'features/design-templates/useGetTemplateEditToken';
import { Dispatch, MouseEvent, SetStateAction, useCallback, useState } from 'react';
import { TemplateSelectionsPlacidTemplate } from 'services/generated/template-service/types.gen';
import { triggerDownload } from 'utils/triggerDownload';
import { assertNonNullable } from 'utils/typescript/nonNullable';

export const useTemplateOperations = (
  setTemplates: Dispatch<SetStateAction<PlacidTemplate[] | null>>,
  createDesignTemplatesOptions: GenerateDesignTemplatesOptions | null
) => {
  const { mutateAsync: getEditToken, isLoading: isGeneratingEditToken } = useGetTemplateEditToken();
  const { mutateAsync: generateImage, isLoading: isGeneratingImage } = useGenerateTemplateImage();
  const showPlacidEditor = useShowPlacidEditorModal();
  const [lastClickedTemplateIndex, setLastClickedTemplateIndex] = useState<number | null>(null);

  const updateIteration = useCallback(
    (index: number) => {
      const increaseTemplateIteration = (t: PlacidTemplate, idx: number): PlacidTemplate => {
        if (idx === index) {
          return { ...t, iterationId: crypto.randomUUID() };
        }
        return t;
      };
      setTemplates(prevState => prevState?.map(increaseTemplateIteration) || null);
    },
    [setTemplates]
  );

  const handleEditTemplateClick = useCallback(
    async (event: MouseEvent<HTMLElement>, template: PlacidTemplate, index: number) => {
      event.preventDefault();

      assertNonNullable(createDesignTemplatesOptions);

      setLastClickedTemplateIndex(index);

      try {
        if (template.isSourceTemplate) {
          const result = await getEditToken({
            templateId: template.uuid,
            width: createDesignTemplatesOptions.width,
            height: createDesignTemplatesOptions.height,
            title: 'Edit template'
          });

          const updateTemplate = (t: PlacidTemplate): PlacidTemplate => {
            if (t.uuid === template.uuid) {
              return {
                ...t,
                uuid: result.templateId,
                accessToken: result.writeToken,
                isSourceTemplate: false
              };
            }
            return t;
          };

          setTemplates(prevState => prevState?.map(updateTemplate) || null);
          showPlacidEditor({
            accessToken: result.writeToken,
            templateId: result.templateId,
            prefilledLayers: template.isSourceTemplate ? template.canvasSchema : {},
            onClose: () => {
              updateIteration(index);
            }
          });
        } else {
          showPlacidEditor({
            accessToken: template.accessToken,
            templateId: template.uuid,
            prefilledLayers: template.isSourceTemplate ? template.canvasSchema : {},
            onClose: () => {
              updateIteration(index);
            }
          });
        }
      } catch (error) {
        Toast.commonError();
      }
    },
    [
      createDesignTemplatesOptions,
      getEditToken,
      setLastClickedTemplateIndex,
      setTemplates,
      showPlacidEditor,
      updateIteration
    ]
  );

  const handleDownloadTemplateClick = useCallback(
    async (
      event: MouseEvent<HTMLElement>,
      template: TemplateSelectionsPlacidTemplate,
      index: number
    ) => {
      event.preventDefault();

      setLastClickedTemplateIndex(index);

      if (!template.layers) {
        Toast.commonError();
        return;
      }

      try {
        const result = await generateImage({
          templateId: template.uuid,
          layers: template.canvasSchema
        });
        if (!result.imageUrl) {
          Toast.commonError();
          return;
        }

        const response = await fetch(result.imageUrl);
        const blob = await response.blob();
        const objectUrl = URL.createObjectURL(blob);

        triggerDownload(objectUrl, 'neuroflash-template.jpg');
      } catch (error) {
        Toast.commonError();
      }
    },
    [generateImage, setLastClickedTemplateIndex]
  );

  return {
    handleEditTemplateClick,
    handleDownloadTemplateClick,
    lastClickedTemplateIndex,
    isGeneratingEditToken,
    isGeneratingImage
  };
};
